<template>
	<div class="star-poster-item">
		<div class="star-poster-item-img-row"  @click="gotoDetail(mediaitem.id,media_type)" v-if="mediaitem">
			<img :src="`${$store.state.home.base_url}${$store.state.home.poster_size[3]}${mediaitem.poster_path}`" class="star-poster-item-img" alt="" />
			<div class="star-info-row">
				<div class="stars-row">
					<div class="full-star star-item" v-for="i in fullStars" :key="i"></div>
					<div class="em-star star-item" v-for="j in emptyStars" :key="`star_${j}`"></div>
				</div>
				<div class="star-num">{{ vote_point }}</div>
			</div>
		</div>
		<div class="poster-title">{{ media_type=='tv'?mediaitem.name:mediaitem.title }}</div>
		<div class="poster-update-time">
			{{ media_type=='tv'?mediaitem.first_air_date:mediaitem.release_date }} 
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			vote_point: 0,
			media_type: null
		};
	},
	computed: {
		fullStars() {
			return Math.floor(Math.floor(this.vote_point) / 2);
		},
		emptyStars() {
			return 5 - this.fullStars;
		},
	},
	created() {
		this.vote_point = Math.trunc(this.mediaitem.vote_average * 10) / 10;
		var postmediatype = this.mediaitem.media_type
		if(this.isTV || postmediatype =="tv"){
			this.media_type = 'tv'
		}
	},
	props: {
		appname: {
			default: " ",
		},
		mediaitem: {
			require: true,
		},
		isTV: {
			default: false,
		},
	},
	methods: {
		gotoDetail(params) {
			// this.$router.push({
			// 	name: 'detail',
			// 	query: {
			// 		poster_id: params,
			// 		media_type: this.media_type
			// 	},
			// })
			window.location.href = `/detail?poster_id=${params}&media_type=${this.media_type}`
		},
	},
};
</script>
